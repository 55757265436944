<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
			
					<a-form-item class="ui-form__item" label="所属影院" name="cinemaIds" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-select placeholder="请选择" v-model:value="formState.cinemaIds" style="min-width: 180px;max-width: 500px;" mode="multiple" @change="onCinemaChange">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" > {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="分账组" name="allocateGroupId">
						<a-select v-model:value="formState.allocateGroupId" placeholder="请选择分账组" style="width: 190px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{ item.title }}<a-tag v-if="item.isDefault" style="margin-left: 6px;" color="blue">默认</a-tag></a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="销售时间" >
						<a-date-picker v-model:value="time" :disabledDate="disabledDate" />
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="18">
			            <span v-permission="['report_wechat_list_export']">
							<ExportReport type="cashTicketStatistics" :searchData="searchData"></ExportReport>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<a-table style="margin-top: 10px;" rowKey="id" :columns="columns" :dataSource="list" :pagination="false" :scroll="{ x: 1400 }">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'date'">
						{{ transDateTime(record.statisticsTime , 1) }}
					</template>
					<template v-if="column.key === 'week'">
						星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.statisticsTime *1000).day()] }}
					</template>
					<template v-if="column.key === 'allocateGroup'">
						{{ record.allocateGroupName }}
						<a-tag v-if="record.isDefault" style="margin-left: 6px;" color="blue">默认</a-tag>
					</template>
				</template>
				
				<template #summary v-if="list && list.length">
					<a-table-summary-row>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell>
							<a-typography-text> 合计：{{ total.ticketPrice.toFixed(2) }}</a-typography-text>
						</a-table-summary-cell>
						<a-table-summary-cell>
							<a-typography-text> 合计：{{ total.ticketSeatNum.toFixed(2) }} </a-typography-text>
						</a-table-summary-cell>
						<a-table-summary-cell>
							<a-typography-text> 合计：{{ total.afterSaleTicketNum.toFixed(0) }} </a-typography-text>
						</a-table-summary-cell>
						<a-table-summary-cell>
							<a-typography-text> 合计：{{ total.totalServiceFee.toFixed(2) }} </a-typography-text>
						</a-table-summary-cell>
					</a-table-summary-row>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import { ledgerGroupList } from '@/service/modules/ledger.js';
	import { cashTicketStatistics } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	export default {
		components: { ExportReport },
		data(){
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				groupList: [],
				time: undefined,
				formState: {
					organizationId: 0,
					cinemaIds: [0],
					allocateGroupId: 0
				},
				searchData: {},
				list:[],
				columns: [{
					title: '销售时间',
					key: 'date'
				}, {
					title: '星期',
					key: 'week'
				}, {
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '分账组',
					key: 'allocateGroup'
				}, {
					title: '金额',
					dataIndex: 'ticketPrice'
				}, {
					title: '座位数',
					dataIndex: 'ticketSeatNum'
				}, {
					title: '退票座位数',
					dataIndex: 'afterSaleTicketNum'
				}, {
					title: '退票服务费',
					dataIndex: 'totalServiceFee'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				total: {}
			}
		},
		created(){
			this.time = this.moment();
			this.getLedgerGroupList();
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.allocateGroupId = this.searchData.allocateGroupId ? this.searchData.allocateGroupId : undefined;
				if (!this.searchData.organizationId) {
					this.searchData.organizationId = undefined;
				}
				if(this.searchData.cinemaIds && this.searchData.cinemaIds.length && this.searchData.cinemaIds.indexOf(0) === -1) {
					this.searchData.cinemaIds = this.searchData.cinemaIds.join(',');
				} else {
					this.searchData.cinemaIds = undefined;
				}
				if (this.time) {
					this.searchData.dateTime = this.moment(this.time.startOf('day')).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.searchData = {};
				this.cinemaAllList = [];
				this.formState.cinemaIds = [0];
				this.time = this.moment();
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.list = [];
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await cashTicketStatistics({
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data;
						this.total = {};
						this.list.forEach(item => {
							this.total.afterSaleTicketNum = (this.total.afterSaleTicketNum || 0) + item.afterSaleTicketNum;
							this.total.totalServiceFee = (this.total.totalServiceFee || 0) + item.totalServiceFee;
							this.total.ticketPrice = (this.total.ticketPrice || 0) + item.ticketPrice;
							this.total.ticketSeatNum = (this.total.ticketSeatNum || 0) + item.ticketSeatNum;
						})
					}
				} catch (error) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getLedgerGroupList() {
				let ret = await ledgerGroupList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.groupList = ret.data.list;
				}
			},
			onCinemaChange(keys) {
				if (keys.indexOf(0) !== -1) {
					keys.splice(keys.indexOf(0), 1);
				}
				if (keys.length === 0) {
					keys = [0];
				}
				this.formState.cinemaIds = keys;
			},
			disabledDate(current) {
			    return current && current > this.moment().endOf('day');
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
